import { CalendarOutlined, FileTextOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form, Row, Select, Col, Button, Table, Result, Spin, Tooltip, List, Card, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../profile/SelectAcademicYear';
import { SelectExam } from '../profile/SelectExam';
import './Custom.css'



export default function AdmitCard(props) {

    const user = useStoreState(state => state.auth.user)
    const loading = useStoreState(state => state.auth.loading)
    const singleStudentMarkView = useStoreState(state => state.auth.singleStudentMarkView)
    const downloadAdmitCard = useStoreActions(state => state.auth.downloadAdmitCard);

    const fetchacademicYearList = useStoreActions((state) => state.auth.fetchacademicYearList);
    const fetchPublicExamList = useStoreActions((state) => state.auth.fetchPublicExamList);
    useEffect(() => {
        fetchacademicYearList(user?.instituteId)
        fetchPublicExamList(user?.instituteId)
    }, [])





    const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;


    //console.log(singleStudentMarkView)
    var isMobile = false; //initiate as false
    if (
        /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
    ) {
        isMobile = true;
    }
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        let payload: any = {
            academicYear: values?.academicYear,
            instituteId: user?.instituteId,
            customStudentId: user?.customStudentId,
            examId: values?.examId,
        }
        downloadAdmitCard(payload)
    }

    return (
        <div className="mt-25 mb-sm-25">
            <Card title="Admit Card" className='custom-card-view'>
                <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} className="mb-sm-25" >
                    <Row gutter={15}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                            <Form.Item
                                name="academicYear"
                                label="Academic Year:"
                                className="title-Text custon-form-wrapper"
                                rules={[
                                    { required: true, message: "Please Select Year" },
                                ]}
                            >
                                <SelectAcademicYear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                            <Form.Item
                                name="examId"
                                label="Exam:"
                                className="title-Text custon-form-wrapper"
                                rules={[
                                    { required: true, message: "Please Select Exam" },
                                ]}
                            >
                                <SelectExam />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item className="mb-sm-0">
                                <Button type="primary" className="success-button" htmlType="submit" style={{ marginTop: isMobile ? 0 : 30, width: "100%", padding: 10 }}>
                                    Download
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Card>
            <div className="loading" style={{ display: loading ? "inherit" : "none" }}> <Spin indicator={antIcon} /></div>
            {singleStudentMarkView !== null &&


<></>
            }

        </div>
    )
}
